// extracted by mini-css-extract-plugin
export var blush = "bz_d4";
export var blushIllustration = "bz_dM";
export var button = "bz_bs";
export var cardHeadline = "bz_dN";
export var cardRoot = "bz_dL";
export var cardSubtitle = "bz_d3";
export var resourcesTileMobileVerticalContainer = "bz_d2";
export var rowTwo = "bz_d1";
export var rowtwosignal = "bz_d5";
export var wrapper = "bz_bq";