// extracted by mini-css-extract-plugin
export var applyBtn = "bD_dw";
export var menuItemClosed = "bD_fk";
export var menuItemOpened = "bD_fh";
export var navClosed = "bD_fg";
export var navContainer = "bD_fd";
export var navOpened = "bD_ff";
export var submenuClosed = "bD_fn";
export var submenuIcon = "bD_fj";
export var submenuItem = "bD_fl";
export var submenuOpened = "bD_fm";